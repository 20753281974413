// Library imports
import React, { Component } from 'react';

import SoundWheel from '../sound/wheel_sound.mp3';
const SoundQuips = [
  () => import('../sound/merica/america_fuck_yeah.mp3'),
  () => import('../sound/merica/covfefe.mp3'),
  () => import('../sound/merica/crap_open_a_cold_one.mp3'),
  () => import('../sound/merica/getting_crappy_in_the_usa.mp3'),
  () => import('../sound/merica/god_bless_this_crap.mp3'),
  () => import('../sound/merica/hot_diggity_dog.mp3'),
  () => import('../sound/merica/hotdog.mp3'),
  () => import('../sound/merica/make_crap_great_again.mp3'),
  () => import('../sound/merica/oh_say_can_you_see.mp3'),
  () => import('../sound/merica/red_white_and_poo.mp3'),
  () => import('../sound/merica/the_american_dream.mp3'),
  () => import('../sound/merica/this_crap_is_your_crap.mp3'),
  () => import('../sound/merica/this_will_fit_in_my_trunk.mp3'),
  () => import('../sound/merica/we_found_crap_in_the_usa.mp3'),
];

const AudioContext = window.AudioContext || window.webkitAudioContext;

class AudioEngine extends Component {
  constructor(props) {
    super(props);

    this.audioElement = null;
    this.audioCtx = null;
  }

  componentDidMount() {
    // console.groupCollapsed('AudioEngine::componentDidMount');
    this.audioElement = document.getElementById('audioPlayer');
    // console.groupEnd();
  }

  static usedQuips = [];

  static getNewQuipNumber() {
    if (this.usedQuips.length >= (SoundQuips.length)) {
      this.usedQuips = [];
    }
    let canidate = (this.getRandomInt(0, SoundQuips.length));
    if (this.usedQuips.includes((canidate))) {
      return this.getNewQuipNumber();
    } else {
      this.usedQuips.push(canidate);
      return canidate;
    }
  }

  static getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }

  static startSequence() {
    if (!this.audioCtx) {
      this.audioCtx = new AudioContext();
    }
    if (this.audioCtx.state === 'suspended') {
      this.audioCtx.resume();
    }

    return new Promise(
      (resolve, reject) => {
        this.audioElement = document.getElementById('audioPlayer');
        this.audioElement.src = SoundWheel;
        this.audioElement.onended = () => {
          resolve(true);
          this.endingSequence();
        };
        this.audioElement.play();
      }
    );
  }

  static endingSequence() {
    return new Promise(
      (resolve, reject) => {
        let randomSelectInt = this.getNewQuipNumber();
        SoundQuips[randomSelectInt]().then((soundFile) => {
          this.audioElement.src = soundFile.default;
          this.audioElement.onended = () => {
            resolve(true);
          };
          this.audioElement.play();
        });
      }
    )
  }

  render() {
    return (
      <audio id="audioPlayer" className="audioPlayer" />
    );
  }
}

export default AudioEngine;
