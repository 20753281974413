// Library imports
import React, { Component } from 'react';
import { withRouter } from "react-router";
import {Helmet} from "react-helmet";
import ReactGA from 'react-ga';

// Custom Imports
import Header from './Header';
import Footer from './Footer';
import MainDisplay from './MainDisplay';
import Fence from './Fence';
import AudioEngine from '../helpers/AudioEngine';
import ProductData from '../helpers/ProductData';


class AppBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSlug: null,
      activeData: {
        title: null,
        description: null,
        slug: null,
        image: null,
        url: null,
      },
      previousSlug: null,
      previousData: {
        title: null,
        description: null,
        slug: null,
        image: null,
        url: null,
      },
      animation: {
        global: {
          doActiveAnimation: false,
        },
        screen: {
          flag: false,
          gearshaft: false,
        },
        infocard: {
          active: false,
        },
        mrdodohead: {
          active: false,
        },
        sound: {
          rollin: false,
        },
      },
      canUseNavigatorShare: Boolean(navigator.share),
      testState: 'A String',
      hasSpunWheel: false,
    }

    this.getNewSlug = this.getNewSlug.bind(this);
    this.animationResetAll = this.animationResetAll.bind(this);
    this.animationSetStatus = this.animationSetStatus.bind(this);
    this.animationIsPostReady = this.animationIsPostReady.bind(this);
    this.goToRoute = this.goToRoute.bind(this);
  }

  componentDidMount() {
    // console.groupCollapsed('App::componentDidMount');
    // console.info('props', this.props);
    // console.info('state', this.state);
    if (this.props.match.params.slug) {
      let newSlug = ProductData.getDataBySlug(this.props.match.params.slug);
      this.setState({
        activeSlug: newSlug.slug,
        activeData: newSlug,
      });
      this.gaSetLocation(`/${newSlug.slug}`);
    } else {
      this.gaSetLocation(`/`);
    }
    // console.groupEnd();
  }

  componentDidUpdate(prevProps, prevState) {
    // console.group('App::componentDidUpdate');
    // console.info('props', prevProps, this.props);
    // console.info('state', prevState, this.state);
    if (this.props.location.pathname === '/' && this.state.activeSlug !== null) {
      this.setState({
        activeSlug: null,
        activeData: null,
      });
    }
    if (
      prevState.previousSlug !== this.state.activeSlug &&
      prevState.activeSlug !== this.state.activeSlug
    ) {
      this.setState({
        previousSlug: prevState.previousSlug,
        previousData: prevState.previousData,
      })
    }
    if (this.state.animation.global.doActiveAnimation && !this.state.animation.sound.rollin && !this.animationIsPostReady()) {
      this.audioStartSequence();
    }
    // console.groupEnd();
  }

  gaSetLocation(locationPathname) {
    ReactGA.pageview(locationPathname);
  }

  animationResetAll(acitveAnimation=false) {
    this.setState({
      animation: {
        global: {
          doActiveAnimation: acitveAnimation,
        },
        screen: {
          flag: false,
          gearshaft: false,
        },
        infocard: {
          active: false,
        },
        mrdodohead: {
          active: false,
        },
        sound: {
          rollin: false,
        },
      }
    })
  }

  animationSetStatus(field, key, value) {
    let tempState = Object.assign({}, this.state.animation);
    tempState[field][key] = value;
    this.setState({
      animation: tempState,
    });

    // Do Checks
    if (this.animationIsPostReady()) {
      if (!this.state.hasSpunWheel) {
        this.setState({
          hasSpunWheel: true,
        });
      }
      tempState.global.doActiveAnimation = false;
      this.setState({
        animation: tempState,
      });
    }
  }

  animationIsPostReady() {
    let returnBool = false;
    let animationState = this.state.animation;
    let trueCount = 0;
    if (!animationState.global.doActiveAnimation) {
      returnBool = true;
    } else {
      for (const [key, value] of Object.entries(animationState)) {
        if (key !== 'global') {
          for (const [keyA] of Object.entries(value)) {
            if (value[keyA]) {
              trueCount++;
            }
          }
        }
      }
      if (trueCount < 1) {
        returnBool = true;
      }
    }
    return returnBool;
  }

  audioStartSequence() {
    this.animationSetStatus('sound', 'rollin', true);
    AudioEngine.startSequence().then((results) => {
      this.animationSetStatus('sound', 'rollin', false);
    });
  }

  getNewSlug() {
    // Gets a new slug and defines approprately.
    // console.info('getNewSlug()');
    this.animationResetAll(true);
    let newSlug = ProductData.getNewItem();
    this.props.history.push(`/${newSlug.slug}`);
    this.gaSetLocation(`/${newSlug.slug}`);
    this.setState({
      activeSlug: newSlug.slug,
      activeData: newSlug,
    });
  }

  goToRoute() {
    this.props.history.push('/');
    this.gaSetLocation('/');
  }

  CustomMetaTags(props) {
    let metaTagsData = {
      title: 'The Amazing CRAP-O-MATIC Gift Generator',
      description: 'The CRAP-O-MATIC: For all your bad gifting needs.',
      slug: null,
      image: `${window.location.protocol}//${window.location.host}/images/crapomatic-share.png`,
      url: `${window.location.protocol}//${window.location.host}`,
    };
    if (props.activeSlug) {
      metaTagsData = {
        title: `CRAP-O-MATIC: ${props.activeData.title}`,
        description: `${props.activeData.description}`,
        image: `${window.location.protocol}//${window.location.host}/products/${props.activeData.image}`,
        url: `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
      }
    }
    // <this.CustomMetaTags />
    return (
      <Helmet defaultTitle="The Amazing CRAP-O-MATIC Gift Generator">
        <title>{metaTagsData.title}</title>
        <link rel="canonical" href={metaTagsData.url} />
        <meta property="og:url" content={metaTagsData.url} />
        <meta property="og:title" content={metaTagsData.title} />
        <meta property="description" content={metaTagsData.description} />
        <meta property="og:image" content={metaTagsData.image} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="The Amazing CRAP-O-MATIC Gift Generator" />
        <meta property="twitter:image" content={metaTagsData.image} />
        <meta property="twitter:site" content="@Traction" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
    )
  }

  render() {
    return (
      <div className="App">
      <this.CustomMetaTags
        activeSlug={this.state.activeSlug}
        activeData={this.state.activeData}
      />
        <Header goToRoute={this.goToRoute} />
        <Fence />
        <section className="mainStage">
          <MainDisplay
            activeSlug={this.state.activeSlug}
            activeData={this.state.activeData}
            animation={this.state.animation}
            getNewSlug={this.getNewSlug}
            animationSetStatus={this.animationSetStatus}
          />
        </section>
        <Footer />
        <AudioEngine />
      </div>
    );
  }
}

const App = withRouter(AppBase);
export default App;
