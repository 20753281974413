// Library imports
import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';

// Assets
import { ReactComponent as MrDoDoHeadSvg } from '../images/mrdodohead.svg';

class MrDoDoHead extends Component {
  constructor(props) {
    super(props);

    this.state = {
      animating: false,
    };

    this.animationStatus = this.animationStatus.bind(this);
  }

  animationStatus(key, value) {
    // console.info(`Job's done for "${key}"!`);
    this.props.animationSetStatus('mrdodohead', key, value);
  }


  render() {
    return (
      <CSSTransition
        in={this.props.isActive}
        timeout={400}
        className="mrDoDoHead"
        classNames="mrDoDoHead"
        onEntering={() => this.animationStatus('active', true)}
        onEntered={() => this.animationStatus('active', false)}
      >
        <div className="mrDoDoHead">
          <MrDoDoHeadSvg />
        </div>
      </CSSTransition>
    );
  }
}

export default MrDoDoHead;
