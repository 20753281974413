// Library imports
import React, { Component } from 'react';

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      
    }
  }
  
  render() {
    return (
      <footer className="footer">
        <p>The Amazing Crap-O-Matic is brought to you by Doo Doo Doode and the patriotic elves at <a href="https://tractionco.com" target="_blank" rel="noopener noreferrer">Traction</a> &copy;2019.</p>
      </footer>
    )
  }
}

export default Footer;
