import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import * as serviceWorker from './serviceWorker';

import Root from './Root';

// Style
import './style/App.scss';

// Constants
const history = createBrowserHistory();

// Google Analytics
const trackingId = 'UA-4792816-4';
ReactGA.initialize(trackingId, {
  siteSpeedSampleRate: 100,
});
history.listen(location => {
  ReactGA.pageview(location.pathname);
});

ReactDOM.render(<Root history={history} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
