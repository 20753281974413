// Library imports
import React, { Component } from 'react';

class Fence extends Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  render() {
    return (
      <div className="fence">
        <div className="sides sideLeft"></div>
        <div className="middle"></div>
        <div className="sides sideRight"></div>
      </div>
    );
  }
}

export default Fence;
