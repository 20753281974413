// Library imports
import React, { Component } from 'react';
import ReactGA from 'react-ga';

// Assets
import { ReactComponent as CrapOMaticTitleSVG } from '../images/title-crapomatic.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faSquare } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare, faTwitterSquare, faRedditSquare } from '@fortawesome/free-brands-svg-icons';

class Header extends Component {
  constructor(props) {
    super(props);

    this.mainUrl = `${window.location.protocol}//${window.location.host}`;

    this.state = {
      canUseShare: (navigator.share),
      shareLinks: {
        reddit: `https://reddit.com/submit?url=${encodeURIComponent(this.mainUrl)}&title=${encodeURIComponent('The Amazing Crap-O-Matic Gift Generator')}`,
        twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(this.mainUrl)}&via=Traction&hashtags=CrapOMatic&text=${encodeURIComponent('The Amazing Crap-O-Matic Gift Generator!')}`,
        facebook: `http://www.facebook.com/sharer.php?u=${encodeURIComponent(this.mainUrl)}`,
      }
    }

    this.ShowShareButtons = this.ShowShareButtons.bind(this);
  }

  shareDialog() {
    let shareURL = `${window.location.protocol}//${window.location.host}`;
    console.group('shareDialog()');
    if (navigator.share) {
      console.info('Using native share sheet.');
      navigator.share({
        title: `The Amazing Crap-O-Matic Gift Generator`,
        url: shareURL,
      })
      .then(() => {
        ReactGA.event({
          category: 'Social Project',
          action: 'Shared via Share Sheet',
          label: 'Root Share',
          value: 1,
        });
      });
    } else {
      console.info('Use fallback to share sheet.', shareURL);
    }
    console.groupEnd();
  }

  shareLink(format) {
    ReactGA.event({
      category: 'Social Project',
      action: `Shared via Link: ${format}`,
      label: 'Root Share',
      value: 1,
    });
  }

  ShowShareButtons() {
    if (this.state.canUseShare) {
      return (
        <ul>
          <li>
            <span onClick={this.shareDialog} className="fa-layers fa-fw">
              <FontAwesomeIcon icon={faSquare} />
              <FontAwesomeIcon icon={faEllipsisH} style={{color:'#329cfc'}} transform="shrink-6" />
            </span>
          </li>
        </ul>
      )
    } else {
      return (
        <ul>
          <li>
            <a href={this.state.shareLinks.facebook} target="_blank" rel="noopener noreferrer" onClick={() => this.shareLink('facebook')}><FontAwesomeIcon icon={faFacebookSquare} /></a>
          </li>
          <li>
            <a href={this.state.shareLinks.twitter} target="_blank" rel="noopener noreferrer" onClick={() => this.shareLink('twitter')}><FontAwesomeIcon icon={faTwitterSquare} /></a>
          </li>
          <li>
            <a href={this.state.shareLinks.reddit} target="_blank" rel="noopener noreferrer" onClick={() => this.shareLink('reddit')}><FontAwesomeIcon icon={faRedditSquare} /></a>
          </li>
        </ul>
      )
    }
  }

  render() {
    return (
      <header className="header">
        <div className="sides subtitle">'Murica Edition</div>
        <div className="main" onClick={() => this.props.goToRoute()}>
          <div className="element main-first">The Amazing</div>
          <div className="element title"><CrapOMaticTitleSVG alt="Crap-O-Matic" /></div>
          <div className="element main-last">Gift Generator</div>
        </div>
        <div className="sides shares">
          <this.ShowShareButtons canUseShare={this.state.canUseShare} />
        </div>
      </header>
    )
  }
}

export default Header;
