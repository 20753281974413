// Library imports
import React, { Component } from 'react';

// Custom Imports
import ProductData from '../helpers/ProductData';

// Assets
import Screen from './Screen';
import ItemInfo from './ItemInfo';

class MainDisplay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      animating: false,
      activeSlug: null,
      activeData: {
        title: null,
        description: null,
        slug: null,
        image: null,
        url: null,
      },
      previousSlug: null,
      previousData: {
        title: null,
        description: null,
        slug: null,
        image: null,
        url: null,
      },
    }

  }

  componentDidMount() {
    ProductData.slugsAdd(this.props.activeSlug);
    this.setState({
      activeSlug: this.props.activeSlug,
      activeData: this.props.activeData,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.activeSlug !== this.props.activeSlug) {
      // console.groupCollapsed('MainDisplay::componentDidUpdate()');
      this.setState({
        activeSlug: this.props.activeSlug,
        activeData: ProductData.getDataBySlug(this.props.activeSlug),
        previousSlug: prevProps.activeSlug,
        previousData: ProductData.getDataBySlug(prevProps.activeSlug)
      });
      // console.groupEnd();
    }
  }

  render() {
    return (
      <div>
        <div className="mainDisplay">
          <Screen
            activeSlug={this.state.activeSlug}
            activeData={this.state.activeData}
            previousSlug={this.state.previousSlug}
            previousData={this.state.previousData}
            getNewSlug={this.props.getNewSlug}
            animation={this.props.animation}
            animationSetStatus={this.props.animationSetStatus}
          />
        </div>
        <ItemInfo
          activeSlug={this.state.activeSlug}
          activeData={this.state.activeData}
          previousSlug={this.state.previousSlug}
          previousData={this.state.previousData}
          animation={this.props.animation}
          animationSetStatus={this.props.animationSetStatus}
        />
      </div>
    );
  }
}

export default MainDisplay;
