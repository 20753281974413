// Library imports
import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import ReactGA from 'react-ga';

// Assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faTwitterSquare, faRedditSquare } from '@fortawesome/free-brands-svg-icons';
import HotdogMain from './HotDogMain';

class ItemInfo extends Component {
  constructor(props) {
    super(props);

    this.mainUrl = `${window.location.protocol}//${window.location.host}`;

    this.state = {
      animating: false,
      activeSlug: this.props.activeSlug,
      activeData: this.props.activeData,
      previousSlug: null,
      previousData: null,
    }

    this.buttonBuyProduct = this.buttonBuyProduct.bind(this);
    this.buttonShareProduct = this.buttonShareProduct.bind(this);
    this.CardContent = this.CardContent.bind(this);
    this.CardContentBuyButton = this.CardContentBuyButton.bind(this);
    this.ShareContent = this.ShareContent.bind(this);
    this.animationStatus = this.animationStatus.bind(this);
  }

  componentDidMount() {
    // console.group('ItemInfo::componentDidMount');
    // console.info('props', this.props);
    // console.info('state', this.state);
    // console.groupEnd();
  }

  componentDidUpdate(prevProps, prevState) {
    // console.group('ItemInfo::componentDidUpdate');
    // console.info('props', prevProps, this.props);
    // console.info('state', prevState, this.state);
    // console.groupEnd();
  }

  buttonBuyProduct() {
    // console.info('Buy Product!', this.props.activeData.url);
    ReactGA.event({
      category: 'Buy Product',
      action: 'click',
      label: this.props.activeData.slug,
      value: 1,
    });
  }

  buttonShareProduct() {
    // console.info('Share product...');
    let shareURL = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
    if (navigator.share) {
      navigator.share({
        title: `Crap-O-Matic: ${this.props.activeData.title}`,
        text: this.props.activeData.description,
        url: shareURL,
      })
      .then(() => {
        ReactGA.event({
          category: 'Social Product',
          action: 'Shared via Share Sheet',
          label: this.props.activeData.slug,
          value: 1,
        });
      });
    } else {
      console.info('Use fallback to share sheet.', shareURL);
    }
  }

  animationStatus(key, value) {
    // console.info(`Job's done for "${key}"!`);
    this.props.animationSetStatus('infocard', key, value);
  }

  shareLink(format, label=null) {
    ReactGA.event({
      category: 'Social Product',
      action: `Shared via Link: ${format}`,
      label: label,
      value: 1,
    });
  }

  ShareContent(props) {
    if (navigator.share) {
      return (
        <div className="buttonItem shareNoHover">
        <button className="btn btn-share" onClick={this.buttonShareProduct}>Share This Crap</button>
        </div>
      )
    } else {

      let shareUrl = encodeURIComponent(`${this.mainUrl}${window.location.pathname}`);
      let shareTitle = encodeURIComponent(`The Amazing Crap-O-Matic Gift Generator: ${this.props.activeData.title}`);
      let shareLinks = {
        reddit: `https://reddit.com/submit?url=${shareUrl}&title=${shareTitle}`,
        twitter: `https://twitter.com/intent/tweet?url=${shareUrl}&via=Traction&hashtags=CrapOMatic&text=${shareTitle}`,
        facebook: `http://www.facebook.com/sharer.php?u=${shareUrl}`,
      };

      return (
        <div className="buttonItem shareHover">
          <button className="btn btn-share btn-share-hoverout">Share This Crap</button>
          <ul className="btn-share-hoverin">
            <li>
              <a href={shareLinks.facebook} target="_blank" rel="noopener noreferrer" onClick={() => this.shareLink('facebook', this.props.activeData.slug)}><FontAwesomeIcon icon={faFacebookSquare} /></a>
            </li>
            <li>
              <a href={shareLinks.twitter} target="_blank" rel="noopener noreferrer" onClick={() => this.shareLink('twitter', this.props.activeData.slug)}><FontAwesomeIcon icon={faTwitterSquare} /></a>
            </li>
            <li>
              <a href={shareLinks.reddit} target="_blank" rel="noopener noreferrer" onClick={() => this.shareLink('reddit', this.props.activeData.slug)}><FontAwesomeIcon icon={faRedditSquare} /></a>
            </li>
          </ul>
        </div>
      )
    }
  }

  CardContentBuyButton(props) {
    if (!props.isPreviousData) {
      return (
        <div className="buttonItem shareNoHover">
          <a href={props.url} target="_blank" rel="noopener noreferrer" className="btn btn-buy" onClick={() => this.buttonBuyProduct()}>Buy Now</a>
        </div>
      )
    } else {
      return (
        <div className="buttonItem shareNoHover">
          <button rel="noopener noreferrer" className="btn btn-buy">Buy Now</button>
        </div>
      )
    }
  }

  CardContent(props) {
    let contentClasses = ['contentItem'];
    if (props.isPreviousData) {
      contentClasses.push('previous');
    }
    if (this.props.animation.global.doActiveAnimation) {
      contentClasses.push('active');
    } else {
      contentClasses.push('inactive');
    }

    if (props.data.title) {
      return (
        <CSSTransition
          in={this.props.animation.global.doActiveAnimation}
          timeout={400}
          className={contentClasses.join(' ')}
          classNames="contentItem"
          onEntering={() => this.animationStatus('active', true)}
          onEntered={() => this.animationStatus('active', false)}
        >
          <div>
            <h2 className="title">{props.data.title}</h2>
            <p className="description">{props.data.description}</p>
            <div className="actions">
              <this.CardContentBuyButton url={props.data.url} isPreviousData={props.isPreviousData} />
              <this.ShareContent />
            </div>
          </div>
        </CSSTransition>
      );
    } else {
      return (
        <CSSTransition
          in={this.props.animation.global.doActiveAnimation}
          timeout={20}
          className={contentClasses.join(' ')}
          classNames="contentItem"
          onEntering={() => this.animationStatus('active', true)}
          onEntered={() => this.animationStatus('active', false)}
        >
          <div>
          </div>
        </CSSTransition>
      );
    }
  }

  render() {
    let itemInfoStageClasses = ['itemInfoStage'];

    if (this.props.activeSlug === null) {
      itemInfoStageClasses.push('inactive');
    } else {
      itemInfoStageClasses.push('active');
    }

    return (
      <div className={itemInfoStageClasses.join(' ')}>
        <div className="itemInfo">
        <div className="cordStage" />
          <div className="content">
            <this.CardContent
              isPreviousData={false}
              data={this.props.activeData}
              buttonBuyProduct={this.buttonBuyProduct}
              buttonShareProduct={this.buttonShareProduct}
            />
          </div>
          <HotdogMain />
        </div>
      </div>
    );
  }
}

export default ItemInfo;
