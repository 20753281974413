import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import App from './components/App'

const Root = ({ history }) => (
  <Router>
    <Route path="/:slug?" component={App} />
  </Router>
)

export default Root
