// Library imports
import React, { Component } from 'react';

// Assets
import { ReactComponent as HotdogMainSvg } from '../images/hotdog-main.svg';
import { ReactComponent as HotdogMiniSvg } from '../images/hotdog-mini.svg';

class HotdogMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      animating: false,
    }
  }

  render() {
    return (
      <div className="hotdogElement">
        <HotdogMainSvg />
        <HotdogMiniSvg className="hotdogMini" />
      </div>
    );
  }
}

export default HotdogMain;
