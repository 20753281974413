// Library imports
import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';

// Assets
import { ReactComponent as ScreenSvg } from '../images/screen.svg';
import { ReactComponent as FlagTransitionSvg } from '../images/flag-transition.svg';
import { ReactComponent as GearshaftSvg } from '../images/gearshaft-main.svg';
import { ReactComponent as CallToActionArrow } from '../images/calltoactionarrow.svg';
import { ReactComponent as ScreenTitleCardSVG } from '../images/screen-title-card.svg';
import MrDoDoHead from './MrDoDoHead';

class Screen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      animating: false,
      animateStart: false,
      animateEnd: false,
      hasActivated: false,
    };

    this.findMoreCrap = this.findMoreCrap.bind(this);
    this.animationStatus = this.animationStatus.bind(this);
    this.ProductImage = this.ProductImage.bind(this);
    this.FlagTransition = this.FlagTransition.bind(this);
    this.GearShaft = this.GearShaft.bind(this);
    this.CallToAction = this.CallToAction.bind(this);
    this.ScreenTitleCard = this.ScreenTitleCard.bind(this);
  }

  findMoreCrap() {
    if (this.props.animation.global.doActiveAnimation) {
      return;
    }
    this.props.getNewSlug();
    this.setState({
      animating: true,
      animateStart: true,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // console.group('Screen::componentDidUpdate');
    // console.info('props', prevProps, this.props);
    // console.info('state', prevState, this.state);
    // console.groupEnd();
  }

  ProductImage(props) {
    let elementClasses = ['productImage'];
    if (props.isPreviousData) {
      elementClasses.push('previous');
    }
    if (this.props.animation.global.doActiveAnimation) {
      elementClasses.push('active');
    } else {
      elementClasses.push('inactive');
    }
    let styleObject = {};
    if (props.data.image) {
      styleObject.backgroundImage = `url('/products/${props.data.image}')`;
    } else {
      styleObject.backgroundImage = null;
    }

    return (
      <div className={elementClasses.join(' ')} style={styleObject} />
    );
  }

  animationStatus(key, value) {
    this.props.animationSetStatus('screen', key, value);
  }

  FlagTransition(props) {
    return (
      <CSSTransition
        in={props.isActive}
        timeout={400}
        className="transitionalFlag"
        classNames="transitionalFlag"
        onEntering={() => this.animationStatus('flag', true)}
        onEntered={() => this.animationStatus('flag', false)}
      >
        <FlagTransitionSvg />
      </CSSTransition>
    )
  }

  GearShaft(props) {
    return (
      <div className="gearMain">
        <CSSTransition
          in={props.isActive}
          timeout={400}
          className="gearShaft"
          classNames="gearShaft"
          onEntering={() => this.animationStatus('gearshaft', true)}
          onEntered={() => this.animationStatus('gearshaft', false)}
        >
          <GearshaftSvg />
        </CSSTransition>
      </div>
    )
  }

  CallToAction(props) {

    let ctaText = 'Find me Crap';
    if (this.state.hasActivated) {
      ctaText = 'Again!';
    }

    return (
      <CSSTransition
        in={props.isActive}
        timeout={400}
        className="callToAction"
        classNames="callToAction"
        onEntered={() => this.setState({hasActivated: true})}
      >
        <div className="callToAction">
          <div className="container">
            <CallToActionArrow />
            <div className="ctaText">
              {ctaText}
            </div>
          </div>
        </div>
      </CSSTransition>
    )
  }

  ScreenTitleCard(props) {
    let elementClasses = ['stageTempBG'];
    if (Boolean(props.data.slug)) {
      elementClasses.push('hasActive');
    }
    let styleObject = {};

    return (
      <div className="stageBg">
        <div className={elementClasses.join(' ')} style={styleObject}></div>
        <ScreenTitleCardSVG />
        <div className="stageBgText">
          <span className="content">This 4th of July, give your loved ones the gift of crap.</span>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="screenAndGear">
        <MrDoDoHead isActive={this.props.animation.global.doActiveAnimation} animationSetStatus={this.props.animationSetStatus} />
        <div className="screenMain">
          <ScreenSvg className="screenOnly" />
          <div className={`productStage ${this.props.animation.global.doActiveAnimation ? 'active' : 'inactive'}`}>
            <this.ScreenTitleCard data={this.props.activeData} />
            <this.ProductImage data={this.props.activeData} isPreviousData={false} />
            <this.ProductImage data={this.props.previousData} isPreviousData={true} />
            <this.FlagTransition
              isActive={this.props.animation.global.doActiveAnimation}
            />
          </div>
          <div className="mobileOnlyButton">
            <button className="btn" onClick={this.findMoreCrap}>Find Me Crap</button>
          </div>
        </div>
        <button className="newCrapZone" onClick={this.findMoreCrap}>
          <this.GearShaft isActive={this.props.animation.global.doActiveAnimation} />
          <this.CallToAction isActive={this.props.animation.global.doActiveAnimation} />
        </button>
      </div>
    );
  }
}

export default Screen;
