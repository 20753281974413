// import React, { Component } from 'react';
import { Component } from 'react';

// Data Store
import Data from '../data/content.json';
let previousSlugs = [];

class ProductData extends Component {
  constructor(props) {
    super(props);

    this.getNewItem = this.getNewItem.bind(this);
    this.getDataBySlug = this.getDataBySlug.bind(this);
  }

  static slugsAdd(slug) {
    if (!previousSlugs.includes(slug) && slug !== null) {
      previousSlugs.push(slug);
      console.info(`${previousSlugs.length} of ${Data.length} products (${((previousSlugs.length / Data.length) * 100).toFixed(1)}%) displayed.`);
    }
  }

  static slugsAvailable() {
    let returnVar = true;
    if (previousSlugs.length >= Data.length) {
      returnVar = false;
    }
    return returnVar;
  }

  static slugsReset() {
    previousSlugs = [];
    console.info(`All ${Data.length} products displayed; Restart anew.`);
  }

  static getNewItem(doNotAdd=false) {
    if (!this.slugsAvailable()) {
      this.slugsReset();
    }

    let randomSeed = Math.floor(Math.random() * Data.length);
    let results = previousSlugs.find((item) => {
      return item === Data[randomSeed].slug;
    });

    if (!results) {
      let newSlug = Data[randomSeed];
      if (!doNotAdd) {
        this.slugsAdd(newSlug.slug);
      }
      return newSlug;
    } else {
      return this.getNewItem();
    }
  }

  static getDataBySlug(slug) {
    // console.info(`getDataBySlug('${slug}')`, Data);
    let foundItem = Data.find((item) => {
      return item.slug === slug;
    });

    if (foundItem) {
      return foundItem;
    } else {
      return {
        title: null,
        description: null,
        slug: null,
        image: null,
        url: null,
      }
    }
  }
}

export default ProductData;
